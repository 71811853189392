<template>
  <div id="Nav" :class="{ 'nav-bgc': isIndex, 'nav-login-bgc': loginPage }">
    <div class="header-box">
      <div class="nav-left">
        <a>
          <img class="logo" src="@/assets/image/nav/logo.png" alt="" />
        </a>
        <div v-if="isIndex" class="title text">
          <span class="title-l">用 知 识 为 爱 赋 能</span>
        </div>
      </div>
      <div class="nav-right text">
        <div v-if="isLogin" class="sign">
          <a-dropdown>
            <a>
              <img
                class="person-l"
                :src="
                  $store.state.userInfo.photo
                    ? $store.state.userInfo.photo
                    : GLOBAL.defaultUserImg
                "
                alt=""
              />
              <span class="person-r">{{
                $store.state.userInfo.userName
                  ? $store.state.userInfo.userName
                  : $store.state.userInfo.mobile
              }}</span>
            </a>
            <a-menu slot="overlay">
              <a-menu-item
                key="overlay2"
                class="login-out"
                @click="FunLoginOut()"
              >
                <a-icon type="poweroff" />
                <span>退出系统</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [],
  // 数据对象
  data() {
    return {
      loginPage: true, //是否为登录页面
      isIndex: true,
      isLogin: false,
    };
  },
  // 事件处理器
  methods: {
    FunLoginOut() {
      this.isLogin = false
      this.$store.commit("clearUserInfo");
    },

    getLoginType(){
      clearInterval(this.timer)
      this.timer = setInterval(()=>{
        this.isLogin = this.$store.state.userInfo.isLogin
      },200)
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getLoginType()
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 计算属性监听
  computed:{},
  // 自定义的侦听器
  watch: {
  },
};
</script>

<style lang="less" scoped>
#Nav {
  background-color: @bg-color;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.nav-bgc {
  background-color: rgba(10, 117, 146, 0.8) !important;
}
.nav-login-bgc {
  background-color: rgba(10, 117, 146, 1) !important;
}

.header-box {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 1400px;
  height: 100%;

  .text {
    height: 24px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #ffffff;
    line-height: 70px;
    letter-spacing: 4px;
  }

  .nav-left {
    display: flex;
    .logo {
      margin-right: 78px;
      width: 154px;
      height: 68px;
    }
    .title {
      color: rgba(255, 255, 255, 0.8);
      .title-r {
        letter-spacing: 2px;
      }
    }
    .menu {
      display: flex;
      cursor: pointer;
      .menu-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 184px;
        height: 70px;
        font-size: 18px;
        font-family: PingFangMedium;
        color: rgba(255, 255, 255, 1);
        .menu-top {
          margin-bottom: 6px;
          font-weight: 400;
        }
        .menu-bot {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .menu-bgc {
        color: rgba(255, 255, 255, 0.95);
        background: rgba(0, 0, 0, 0.19);
        .menu-top {
          font-weight: 500;
        }
        .menu-bot {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  .nav-right {
    display: flex;
    letter-spacing: 1px;
    .sign {
      margin-right: 60px;
      .person-l {
        margin-right: 12px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      img {
        cursor: pointer;
      }
      span {
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

// 小屏分辨率内容区呈现1200px布局
// @media screen and (max-width: 16000px) {
//   .header-box {
//     .nav-left {
//       .menu {
//         .menu-list {
//           font-size: 14px;
//         }
//       }
//     }
//     .text{
//       font-size: 14px;
//     }
//   }
// }
</style>
