export default[
  {
    path: '/',
    redirect: 'shopHome'
  },
  // 书课专区首页
  {
    path: 'shopHome',
    component: () => import('@/views/knowledgeMall/newShoppingMall.vue')
  },
  // 改版商城---书课专区首页 
  // {
  //   path: 'newShoppingMall',
  //   component: () => import('@/views/knowledgeMall/newShoppingMall.vue')
  // },
  // 非教育课程列表
  {
    path: 'educationalCurriculum',
    component: () => import('@/views/knowledgeMall/newProductList.vue')
  },
   // 教育课程列表
   {
    path: 'educatList',
    component: () => import('@/views/knowledgeMall/educatList.vue')
  },
  // 套餐列表
  {
    path: 'setmeal',
    component: () => import('@/views/knowledgeMall/setmeal.vue')
  },
  // 教育课程详情
  {
    path: 'courseDetail',
    component: () => import('@/views/knowledgeMall/courseDetail.vue')
  },
  // 教育课程详情-康语定制页面
  {
    path: 'courseBuy',
    component: () => import('@/views/knowledgeMall/courseBuy.vue')
  },
  // 套餐详情
  {
    path: 'setMealDetail',
    component: () => import('@/views/knowledgeMall/setMealDetail.vue')
  },
  // 图书教具详情
  {
    path: 'curriculumDetail',
    component: () => import('@/views/knowledgeMall/curriculumDetail.vue')
  },
  // 模拟考试详情
  {
    path: 'examDetail',
    component: () => import('@/views/knowledgeMall/examDetail.vue')
  },
  // 模拟考试系统
  {
    path: 'examSystem',
    component: () => import('@/views/knowledgeMall/examSystem.vue')
  },
  // 订单详情
  {
    path: 'orderDetail',
    component: () => import('@/views/knowledgeMall/orderDetail.vue')
  },
  // 商品评价
  {
    path: 'evaluation',
    component: () => import('@/views/knowledgeMall/evaluation.vue')
  },
  // 优惠券详情
  {
    path: 'couponDetail',
    name: 'couponDetail',
    component: () => import('@/views/knowledgeMall/couponDetail.vue')
  },
  // pdf预览
  {
    path: 'PdfView',
    component: () => import('@/views/knowledgeMall/PdfView.vue')
  },
  // 改版商品列表页面
  {
    path: 'newProductList',
    component: () => import('@/views/knowledgeMall/newProductList.vue')
  }
]